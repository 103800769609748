import React, { useRef, useState } from 'react';
import Header from '../Components/Header/Header';
import Footer from '../Components/Footer/Footer';

import {ButtonsCards} from '../Components/ButtonsCards/ButtonsCards';
import NavBar from '../Components/NavBar/NavBar';


const Home = () => {


  return (
    <div className="App">
      <NavBar/>
      <Header />
      <ButtonsCards/>
      <Footer/>
    </div>
    
  );
};

export default Home;
