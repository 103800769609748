import { ArbolBotones } from "./Buttons"
import fondo from "../../Imgs/tree.png"
import { Sugerencias } from "../../Utils/Sugerencias";

const styles = {
 

   
  };
  


export const ArbolEnlace = () => {
    const links = [
      { name: "App de Voz", url: "/appvoz" },
      //{ name: "Facebook", url: "https://facebook.com" },
     // { name: "GitHub", url: "https://github.com" },
      //{ name: "Mi Sitio Web", url: "https://mi-sitio.com" },
    ];
  
    return (
      <div className="arbol__container">
        
        <div className="arbol__links">
          {links.map((link, index) => (
            <ArbolBotones key={index} name={link.name} url={link.url} />
          ))}
        </div>
       
      </div>
    );
  };

  