import React from 'react';

const Header = () => {
  return (
    <header id="form__header">
      <h1>App de Voz</h1>
      <div className="form__intro">
        <div className="form__intro--epigrafre">
        <p>simulador de carga de libros
        </p>
 
        
        </div>
        
      </div>
    </header>
    
  );
};

export default Header;
