import React, { useState } from 'react';
import Swal from 'sweetalert2';
import MicIcon from '@mui/icons-material/Mic';



function Form() {
  const [formData, setFormData] = useState({
    titulo: "",
    autor: "",
    genero: "",
    isbn: "",
    descripcion: ""
  });

  const [isListening, setIsListening] = useState(false);

  const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
  const recognition = new SpeechRecognition();

  recognition.lang = "es-ES";
  recognition.interimResults = false;

  const startListening = (field) => {
    setIsListening(true);
    recognition.start();

    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      setFormData((prevData) => ({ ...prevData, [field]: transcript }));
      setIsListening(false);
    };

    recognition.onerror = (event) => {
      console.error("Error de reconocimiento de voz:", event.error);
      setIsListening(false);
    };
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Libro cargado:", formData);
    // datos a  base de datos

    // Mostrar mensaje de agradecimiento
    Swal.fire({
      title: '¡Gracias!',
      text: 'El libro ha sido cargado exitosamente.',
      icon: 'success',
      confirmButtonText: 'Aceptar'
    });


    // blanquea datos
    setFormData({
    titulo: "",
    autor: "",
    genero: "",
    isbn: "",
    descripcion: ""
  })
  };


  return (
  
     
    <form onSubmit={handleSubmit} className="book-form">
      <div className="form-group">
        <label>ISBN:</label>
        <input
          type="text"
          name="isbn"
          value={formData.isbn}
          onChange={handleChange}
          className="form-input"
        />
        <button
          type="button"
          onClick={() => startListening("isbn")}
          className="voice-button"
          aria-label="Escuchar para completar ISBN"
        >
          {isListening ? "🔴" : <MicIcon />  }
        </button>
      </div>
      <div className="form-group">
        <label>Título:</label>
        <input
          type="text"
          name="titulo"
          value={formData.titulo}
          onChange={handleChange}
          className="form-input__titulo"
        />
        <button
          type="button"
          onClick={() => startListening("titulo")}
          className="voice-button"
          aria-label="Escuchar para completar título"
        >
          {isListening ? "🔴" : <MicIcon /> }
        </button>
      </div>
      <div className="form-group">
        <label>Autor:</label>
        <input
          type="text"
          name="autor"
          value={formData.autor}
          onChange={handleChange}
          className="form-input"
        />
        <button
          type="button"
          onClick={() => startListening("autor")}
          className="voice-button"
          aria-label="Escuchar para completar autor"
        >
          {isListening ? "🔴" : <MicIcon /> }
        </button>
      </div>
      <div className="form-group">
        <label>Genero:</label>
        <input
          type="text"
          name="genero"
          value={formData.genero}
          onChange={handleChange}
          className="form-input"
        />
        <button
          type="button"
          onClick={() => startListening("genero")}
          className="voice-button"
          aria-label="Escuchar para completar género"
        >
          {isListening ? "🔴" : <MicIcon /> }
        </button>
      </div>
      <div className="form-group">
        <label>Descripción:</label>
        <textarea
          name="descripcion"
          value={formData.descripcion}
          onChange={handleChange}
          className="form-input textarea"
        />
        <button
          type="button"
          onClick={() => startListening("descripcion")}
          className="voice-button"
          aria-label="Escuchar para completar descripción"
        >
          {isListening ? "🔴" : <MicIcon /> }
        </button>
      </div>
      <button type="submit" className="submit-button">
        Cargar Libro
      </button>
    </form>
 
           
   
  );
  
}

export default Form;