import { Link } from "react-router-dom";

export const ArbolBotones = ({ name, url }) => {

    const styles = {
        button: {
          display: "block",
          textAlign: "center",
          padding: "15px",
          margin: "10px 0",
          textDecoration: "none",
          fontWeight: "bold",
          color: "#fff",
          backgroundColor: "#007bff",
          borderRadius: "5px",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          transition: "background-color 0.3s",
        },
        buttonHover: {
          backgroundColor: "#0056b3",
        },
      };
    return (
      <Link
        to={url}
      
        className="buttons-links"
      >
        <div className="flex-boton">
        <div className="cuadrado"></div>
        <div className="cuadrado2"> {name}</div>
       
        </div>
      </Link>
  
    );
  };