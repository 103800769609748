import React, { useState } from 'react';
import Swal from 'sweetalert2';
import MicIcon from '@mui/icons-material/Mic';
import Form from './form';
import Header from './Header';
import { Sugerencias } from '../../Utils/Sugerencias';



export const Formulario = ( ) => {
  const [formData, setFormData] = useState({
    titulo: "",
    autor: "",
    genero: "",
    isbn: "",
    descripcion: ""
  });

  const [isListening, setIsListening] = useState(false);

  const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
  const recognition = new SpeechRecognition();

  recognition.lang = "es-ES";
  recognition.interimResults = false;

  const startListening = (field) => {
    setIsListening(true);
    recognition.start();

    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      setFormData((prevData) => ({ ...prevData, [field]: transcript }));
      setIsListening(false);
    };

    recognition.onerror = (event) => {
      console.error("Error de reconocimiento de voz:", event.error);
      setIsListening(false);
    };
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Libro cargado:", formData);
    // datos a  base de datos

    // Mostrar mensaje de agradecimiento
    Swal.fire({
      title: '¡Gracias!',
      text: 'El libro ha sido cargado exitosamente.',
      icon: 'success',
      confirmButtonText: 'Aceptar'
    });


    // blanquea datos
    setFormData({
    titulo: "",
    autor: "",
    genero: "",
    isbn: "",
    descripcion: ""
  })
  };


  return (
    <div className="form-background">
     <Header/>
    <Form/>

           
    </div>
  );
  
}

export default Formulario;

