
import './Styles/Global.scss';
import Home from './Pages/Home';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Formulario from './Apps/Formulario/formulario';
import NavBar from './Components/NavBar/NavBar';
import { ArbolEnlace } from './Pages/Treelinks/TreeLinks';
import Sorteo from './Apps/Sorteo/Sorteo';


function App() {
  return (

    <Router>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/appvoz" element={<Formulario/>} />
        <Route path="/treelinks" element={<ArbolEnlace/>} />
        <Route path="/sorteo" element={<Sorteo/>} />

      </Routes>
    </Router>

  );
}

export default App;
