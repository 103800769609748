import React, { useState } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import confetti from 'canvas-confetti';



function Sorteo()  {
    const [names, setNames] = useState('');
    const [nameList, setNameList] = useState([]);
    const [winner, setWinner] = useState(null);
  
    const handleInputChange = (e) => {
      setNames(e.target.value);
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      const nameArray = names.split(',').map(name => name.trim()).filter(name => name !== '');
      setNameList(nameArray);
      setNames('');
    };
  
    const handleDraw = () => {
      if (nameList.length > 0) {
        const randomIndex = Math.floor(Math.random() * nameList.length);
        const selectedWinner = nameList[randomIndex];
        setWinner(selectedWinner);
        announceWinner(selectedWinner);
      }
    };
  
    const announceWinner = (winnerName) => {
      const MySwal = withReactContent(Swal);
      MySwal.fire({
        title: '¡Tenemos un ganador!',
        text: `El ganador es: ${winnerName}`,
        icon: 'success',
        confirmButtonText: '¡Genial!',
        willClose: () => {
            
          confetti({
         
            particleCount: 100,
            spread: 70,
            origin: { y: 0.6 },
            colors: ['#bb0000', '#ffffff', '#00bb00', '#0000bb', '#ffff00'],
          });
        }
      });
    };
  
    return (
      <div className="App__sorteo">
        
        <h1>Sorteo de Nombres</h1>
        <p><strong>instruccion:</strong> agregar nombre separadors por (coma) </p>
        <form onSubmit={handleSubmit}>
          <input 
            type="text" 
            value={names} 
            onChange={handleInputChange} 
            placeholder="Ingresa nombres separados por comas"
          />
          <button type="submit" className='btn btn-primary'> Agregar Nombres</button>
        </form>
  
        {nameList.length > 0 && (
          <div>
            <h2>Lista de Nombres:</h2>
            
            <ul>
              {nameList.map((name, index) => (
                <li key={index}>{name}</li>
              ))}
            </ul>
            <button onClick={handleDraw} className='btn btn-success' >Sortear</button>
          </div>
        )}
    
        {winner && (
          <div>
            <h2>Ganador:</h2>
            <p>{winner}</p>
          </div>
        )}
      </div>
    );
  }

export default Sorteo;
